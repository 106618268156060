import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import cn from 'clsx';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import FeatureContainer from '../components/FeatureContainer';
import TrustedBy from '../components/TrustedBy';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';

import NewFaq from '../components/NewFaq/NewFaq';
import FeaturesGrid from '../components/FeaturesGrid/FeaturesGrid';
import Reviews from '../components/Reviews';
import { pdfImageFeatures } from '../utils/contentProvider';
import { faqDataPdfAnnotation } from '../utils/contentProvider/faqDataCommon';

import 'styles/usecases.scss';
import 'styles/bug-tracking.scss';

const PDFAnnotation = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  return (
    <Layout
      location={location}
      hideTopBar
      productPage
      product="Website Feedback Tool">
      <Meta
        title="Online PDF Annotator by Ruttl"
        description="Annotate PDF files online. No installation needed. Markup, annotate, or comment on PDF files with ease. Trusted by 15,000+ creators and professionals."
        url="https://ruttl.com/pdf-annotation/"
      />
      <div
        id="demo-modal"
        className={cn(['demo-modal', { show: showVideoModal }])}>
        <button
          type="button"
          className="button-unstyled"
          onClick={toggleVideoModal}>
          <img src="/assets/img/close.svg" alt="close" title="close" />
        </button>
        {showVideoModal && (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/WmHna-qA0xA"
            frameBorder="0"
            title="ruttl demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>

      <main className="styled-main">
        <main className="features-hero">
          <div className="container">
            <div className="hero-content">
              <div className="hero-desc">
                <div className="sub-product sub-product--pdf">
                  <img src="/assets/img/add-plugin.svg" alt="" width={18} />
                  <span>
                    PDF/Image Review is a sub product of Website Feedback Tool
                  </span>
                </div>
                <h1>
                  Online PDF Annotator to help annotate and markup 10x faster
                </h1>
                <p className="sub-text">
                  Upload any PDF file and start adding annotations, comments and
                  markup right away.
                </p>

                <a
                  href="https://web.ruttl.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button">
                  Try it now for free
                </a>
                <a
                  href="https://calendly.com/setup-demo-with-ruttl/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="button"
                  className="button book-demo">
                  Book a Demo
                </a>
                <p className="sub-text-small">
                  Review PDF files with your team within minutes
                </p>
              </div>
              <div className="hero-img">
                <img src="/assets/img/pdf-image-home.png" alt="" />
              </div>
            </div>
          </div>

          <TrustedBy />
          <section
            className="styled-bug-tracking"
            style={{ backgroundColor: '#fff' }}
            id="how-it-works">
            <div className="container">
              <div style={{ maxWidth: 544, margin: 'auto' }}>
                <h2 className="center" id="view-demo">
                  Best PDF annotation software to annotate and markup PDFs
                  online
                </h2>
              </div>
              <div className="flex-2 flex flex-wrap align-items-stretch">
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/upload-pdf.png"
                          alt="upload pdf icon"
                          title="upload pdf icon"
                          style={{ width: 50 }}
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Upload PDF Document</h3>
                        <p>
                          Choose any offline PDF document and upload it into
                          your ruttl project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/markup-with-comment.svg"
                          alt="Markup pdf icon"
                          title="Markup pdf icon"
                          style={{ width: 50 }}
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Markup with comments</h3>
                        <p>
                          Click anywhere, type your feedback in the comment box
                          and share your thoughts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/share-project.svg"
                          alt="Share project icon"
                          title="Share project icon"
                          style={{ width: 50 }}
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Share project link</h3>
                        <p>
                          Share the feedback by inviting your team members or
                          using the guest sharing link.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="button"
                type="button"
                onClick={toggleVideoModal}>
                See how it works
              </button>
            </div>
          </section>
        </main>

        <div className="usecases-styled-main">
          <section className="section features-main" id="features">
            <div className="container">
              <div className="section-head">
                <h2 className="center" style={{ maxWidth: 700 }}>
                  Annotate PDF files on Mac, Windows and Linux, straight from
                  your browser
                </h2>
              </div>

              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/textual-comment.png"
                          alt="Add comments to your pdfs"
                          title="Add comments to your pdfs"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">Add textual comments</h3>
                      <p>
                        Share pixel-pinned contextual feedback directly on
                        static images & PDFs.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/tag-team-members.png"
                          alt="Tag your team members"
                          title="Tag your team members"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">Tag your team members</h3>
                      <p>
                        Collaborate with your colleagues and assign them
                        deadlines, tasks on the go.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/video-comment-feedback.png"
                          alt="Add video comments"
                          title="Add video comments"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h3 className="feature-head">Add video comments</h3>
                      <p>
                        Give feedback through textual comments or screen
                        recordings.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </section>

          <FeaturesGrid data={pdfImageFeatures} productType="pdf-annotation" />
          <TestimonialsSlider />
          <Reviews />
          <NewFaq data={faqDataPdfAnnotation} />
        </div>
      </main>
    </Layout>
  );
};

PDFAnnotation.propTypes = {
  location: PropTypes.object,
};

export default PDFAnnotation;
