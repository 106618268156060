import React from 'react';
import 'styles/components/Reviews.scss';

const Reviews = () => (
  <section className="reviews-section" id="reviews">
    <div className="container">
      <div>
        <h2 className="center">See why people love us</h2>
      </div>
      <div className="reviews flex flex-wrap align-items-center justify-content-between">
        <div className="flex column align-items-center  gap-4">
          <img
            src="/assets/img/logo/capterra-logo.png"
            className="review-logo"
            alt="Capterra Logo"
          />
          <h3>Capterra</h3>
          <div className="review-desc">
            <span>33 reviews:&nbsp;</span>
            <img
              src="/assets/img/stars.svg"
              style={{ maxWidth: 64 }}
              alt="4.6 Stars Rating"
            />
            <span className="rating-text">&nbsp;4.6</span>
          </div>
          <a
            href="https://www.capterra.com/p/202384/Ruttl/reviews/"
            target="_blank"
            rel="noreferrer"
            className="read-review-button button-unstyled"
            type="button">
            Read Reviews
          </a>
        </div>
        <div className="flex column align-items-center gap-4">
          <img
            src="/assets/img/logo/sourceforge-logo.png"
            className="review-logo"
            alt="Sourceforge Logo"
          />
          <h3>Sourceforge</h3>
          <div className="review-desc">
            <span>6 reviews:&nbsp;</span>
            <img
              src="/assets/img/stars.svg"
              style={{ maxWidth: 64 }}
              alt="4.8 Stars Rating"
            />
            <span className="rating-text">&nbsp;4.8</span>
          </div>

          <a
            href="https://sourceforge.net/software/product/ruttl/"
            target="_blank"
            rel="noreferrer"
            className="read-review-button button-unstyled"
            type="button">
            Read Reviews
          </a>
        </div>
        <div className="flex column align-items-center  gap-4">
          <img
            src="/assets/img/logo/getapp-logo.png"
            className="review-logo"
            alt="Get App Logo"
          />
          <h3>GetApp</h3>
          <div className="review-desc">
            <span>33 reviews:&nbsp;</span>
            <img
              src="/assets/img/stars.svg"
              style={{ maxWidth: 64 }}
              alt="4.6 Star Rating"
            />
            <span className="rating-text">&nbsp;4.6</span>
          </div>
          <a
            href="https://www.getapp.com/collaboration-software/a/ruttl/"
            target="_blank"
            rel="noreferrer"
            className="read-review-button button-unstyled"
            type="button">
            Read Reviews
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Reviews;
